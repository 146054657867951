import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Footer from '@/components/Footer.vue';
import PrivacyContent from '@/components/privacy/content.vue';
export default defineComponent({
  name: 'Privacy',
  components: {
    HeaderComponent: Header,
    Breadcrumb: Breadcrumb,
    FooterComponent: Footer,
    PrivacyContent: PrivacyContent
  },
  inject: ['isMobile']
});